import Vue from "vue";
import Vuex from "vuex";
import ArticleModule from "./ArticleModule";
import PostModule from "./PostModule";
import VideoModule from "./VideoModule";
import axios from "axios";
import { BASE_URL, APP_KEY } from "../constants/index"
Vue.use(Vuex);
export default new Vuex.Store({
  modules: {
    a: ArticleModule,
    p: PostModule,
    v: VideoModule,
  },
  state: {
    drawer: false,
   loading: false,
    error: null,
    subscribe: null,
    subscribeLoading: false,
    tags: [],
    tag: '',
    search: '',

  },
  getters: {

    loading(state) {
      return state.loading;
    },
    subscribeLoading(state) {
      return state.subscribeLoading;
    },
    error(state) {
      return state.error;
    },
    subscribe(state) {
      return state.subscribe;
    },
    tags(state) {
      return state.tags;
    },
    tag(state) {
      return state.tag;
    },
    search(state) {
      return state.search;
    },
   
  },
  mutations: {
    setDrawer: (state, payload) => (state.drawer = payload),
    toggleDrawer: state => (state.drawer = !state.drawer),
    setLoading(state, payload) {
      state.loading = payload;
    },
    setError(state, payload) {
      state.error = payload;
    },
    clearError(state) {
      state.error = null;
    },
    setSubscribe(state, payload) {
      state.subscribe = payload;
    },
    setSubscribeLoading(state, payload) {
      state.subscribeLoading = payload;
    },
    setTags(state, payload) {
      state.tags = payload;
    },
    setTag(state, payload) {
      state.tag = payload;
    },
    setSearch(state, payload) {
      state.search = payload;
    },
  },

  actions: {
    //Clear Error
    clearError({ commit }) {
      commit('clearError')
    },
    //Subscribe
    postSubscribe(context, payload) {
       context.commit("setSubscribeLoading", true);
      context.commit("clearError");
      axios
        .post(
          BASE_URL +
          `/subscribe?email=${payload}`,{},
          {
            headers: {
              Accept: "application/json",
              APP_KEY: APP_KEY,
            },
          }
        )

        .then((response) => response.data)
        .then((subscribe) => {
          context.commit("setSubscribeLoading", false);
          context.commit("setSubscribe", subscribe);
        })
        .catch((error) => {
          console.log(error);
        });
    },
     // Get tags
     getTagsByType(context,payload) {
      context.commit("setLoading", true);
      context.commit("clearError");
      axios
        .get(
            BASE_URL + `/tags/type/${payload}`,
          {
            headers: {
              Accept: "application/json",
              APP_KEY: APP_KEY,
            },
          }
        )

        .then((response) => response.data)
        .then((tags) => {
          context.commit("setLoading", false);
          context.commit("setTags", tags);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
});
