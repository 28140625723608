<template>
  <v-container>
    <v-row v-if="!loading" justify="center">
      <v-col xl="4" lg="4" md="4" sm="12" xs="12">
        <base-card>
          <v-card-title>
            {{ post.data.title }}
          </v-card-title>
          <v-card-subtitle> {{ post.data.date | moment("dddd, MMMM Do YYYY") }}</v-card-subtitle>
          <a :href="baseImageUrl + post.data.image_name" target="_blank">
            <v-img :src="baseImageUrl + post.data.image_name" gradient="rgba(0, 0, 0, .30), rgba(0, 0, 0, .30)"
          
            aspect-ratio="1">
            </v-img>
          </a>
        
          <v-card-text v-html="post.data.text">
          </v-card-text>
        </base-card>
      </v-col>
    </v-row>
    <progress-circular />
  </v-container>
</template>
<script>
import { BASE_IMAGE_URL } from "../constants/index";
// Utilities
import {
  mapGetters,

} from 'vuex'
export default {
  name: 'Post',
  data: () => ({
    id: 1,
    baseImageUrl: BASE_IMAGE_URL,
  }),

  computed: {
    ...mapGetters(['loading', 'post']),
  },
  created() { this.getPost() },
  methods: {
    getPost() {
      this.$store.dispatch('getPost', this.$route.params.id)
    }
  }
}
</script>

<style>
.v-image__image {
  transition: .3s linear;
}
</style>
