<template>

    <v-col cols="12" class="text-center">
        <base-subheading class="mb-3">{{title}}</base-subheading>
        <v-responsive class="mx-auto mb-4" width="56">
          <v-divider class="mb-1"></v-divider>
          <v-divider></v-divider>
        </v-responsive>
      </v-col>
  </template>
  
  <script>
    export default {
      name: 'BaseMainTitle',
      props: {
      title: {
      type: String,
      required: true,
      }
    },
  
    }
  </script>
  