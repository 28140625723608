import axios from "axios";
import { BASE_URL, APP_KEY } from "../constants/index"

const ArticleModule = {
  state: {
    articles: [],
    article: {},
    searchLoading: false,
    newestArticles: [],

  },
  getters: {
    articles(state) {
      return state.articles;
    },
    article(state) {
      return state.article;
    },
    searchLoading(state) {
      return state.loading;
    },
    newestArticles(state) {
      return state.newestArticles;
    },

  },
  mutations: {
    setArticles(state, payload) {
      state.articles = payload;
    },
    setArticle(state, payload) {
      state.article = payload;
    },
    setSearchLoading(state, payload) {
      state.searchLoading = payload;
    },
    setNewestArticles(state, payload) {
      state.newestArticles = payload;
    },

  },

  actions: {
    // Get paginates articles
    getArticles(context, payload) {
      context.commit("setLoading", true);
      context.commit("clearError");
      axios
        .get(
          BASE_URL +
          `/articles?page=${payload.page}&search=${payload.search}&tag=${payload.tag}&exceptTag=${payload.exceptTag}`,
          {
            headers: {
              Accept: "application/json",
              APP_KEY: APP_KEY,
            },
          }
        )

        .then((response) => response.data)
        .then((articles) => {
          context.commit("setLoading", false);
          context.commit("setArticles", articles);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // Get article
    getArticle(context, payload) {
      context.commit("setLoading", true);
      context.commit("clearError");
      axios
        .get(
          BASE_URL + `/articles/${payload}`,
          {
            headers: {
              Accept: "application/json",
              APP_KEY: APP_KEY,
            },
          }
        )

        .then((response) => response.data)
        .then((article) => {
          context.commit("setLoading", false);
          context.commit("setArticle", article);

        })
        .catch((error) => {
          console.log(error);
        });
    },

    // Get newest articles
    getNewestArticles(context) {
      context.commit("setLoading", true);
      context.commit("clearError");
      axios
        .get(
          BASE_URL + "/articles?page=1",
          {
            headers: {
              Accept: "application/json",
              APP_KEY: APP_KEY,
            },
          }
        )

        .then((response) => response.data)
        .then((newestArticles) => {
          context.commit("setLoading", false);
          context.commit("setNewestArticles", newestArticles);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  
  },
};

export default ArticleModule;

