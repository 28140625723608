import Vue from 'vue'
import Router from 'vue-router'
import Home from '../views/Home.vue'
import Articles from '../views/Articles.vue'
import Article from '../views/Article.vue'
import Post from '../views/Post.vue'
import Posts from '../views/Posts.vue'
import Videos from '../views/Videos.vue'
import Video from '../views/Video.vue'


Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
    },
    {
      path: '/articles',
      name: 'articles',
      component: Articles,
    },
    {
      path: '/article/:id',
      name: 'article',
      component: Article
    },
    {
      path: '/post/:id',
      name: 'post',
      component: Post
    },
    {
      path: '/posts',
      name: 'posts',
      component: Posts,
    },
    {
      path: '/videos',
      name: 'videos',
      component: Videos,
    },
    {
      path: '/video/:id',
      name: 'video',
      component: Video,
    },
    
  ],
})
