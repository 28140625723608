<template>
  <v-btn
    :style="styles"
    fab
    small
    v-bind="$attrs"
    v-on="$listeners"
   color="primary"
  >
    <slot />
  </v-btn>
</template>

<script>
  export default {
    name: 'BaseBtn',

    props: {
      square: Boolean,
    },

    computed: {
      styles () {
        return {
          borderRadius: this.square ? '2px' : undefined,
        }
      },
    },
  }
</script>
