<template>
  <v-container>
    <v-row v-if="!loading" justify="center">
      <v-col xl="8" lg="8" md="12" sm="12" xs="12">
        <base-card class="mx-auto mt-2" color="rgba(203, 170, 92, 0.10)">
          <LazyYoutube :src="'https://www.youtube.com/watch?v=' + video.data.video_id" aspectRatio="16:9"
            thumbnailQuality="maxres" maxWidth="1024" autoplay :showTitle="false">
          </LazyYoutube>
          <v-card-text>
            <h3 class="title font-weight-bold mb-2 text-truncate">
              {{ video.data.title }}
            </h3>
            <div class="caption">
              <!-- {{ value.author }}
                <br> -->
              {{ video.data.date | moment("dddd, MMMM Do YYYY") }}
            </div>
          </v-card-text>
        </base-card>
      </v-col>
    </v-row>
    <progress-circular />
  </v-container>
</template>
<script>
import { BASE_IMAGE_URL } from "../constants/index";
// Utilities
import {
  mapGetters,

} from 'vuex'
export default {
  name: 'Video',
  data: () => ({
    id: 1,
    baseImageUrl: BASE_IMAGE_URL,
  }),

  computed: {
    ...mapGetters(['loading', 'video']),
    breadItems() {
      let items = [
        {
          text: "الرئيسية",
          disabled: false,
          href: "/",
        },
        {
          text: this.video.data.data.title,
          disabled: true,
        },
      ];
      return items;
    },
  },
  created() { this.getVideo() },
  methods: {
    getVideo() {
      this.$store.dispatch('getVideo', this.$route.params.id)
    }
  }
}
</script>
    
    