import axios from "axios";
import { BASE_URL, APP_KEY } from "../constants/index"

const PostModule = {
  state: {
    posts: [],
    post:{},
    homePosts: [],
    newsPosts:[],
  },
  getters: {
    posts(state) {
      return state.posts;
    },
    post(state) {
      return state.post;
    },
    newsPosts(state) {
      return state.newsPosts;
    },
  
  },
  mutations: {
    setPosts(state, payload) {
      state.posts = payload;
    },
    setPost(state, payload) {
      state.post = payload;
    },
    setNewsPosts(state, payload) {
      state.newsPosts = payload;
    },
  
  },

  actions: {
    // Get Posts
    getPosts(context, payload) {
      context.commit("setLoading", true);
      context.commit("clearError");
      axios
        .get(
            BASE_URL + 
      
            `/posts?page=${payload.page}&search=${payload.search}&tag=${payload.tag}&exceptTag=${payload.exceptTag}`,
          {
            headers: {
              Accept: "application/json",
              APP_KEY: APP_KEY,
            },
          }
        )

        .then((response) => response.data)
        .then((posts) => {
          context.commit("setLoading", false);
          context.commit("setPosts", posts);

        })
        .catch((error) => {
          context.commit("setLoading", false);
          context.commit("setError", error);
          console.log(error)
        });
    },
      // Get Post
      getPost(context, payload) {
        context.commit("setLoading", true);
        context.commit("clearError");
        axios
          .get(
            BASE_URL + `/posts/${payload}`,
            {
              headers: {
                Accept: "application/json",
                APP_KEY: APP_KEY,
              },
            }
          )
  
          .then((response) => response.data)
          .then((post) => {
            context.commit("setLoading", false);
            context.commit("setPost", post);
  
          })
          .catch((error) => {
            console.log(error);
          });
      },

     // Get News Posts
     getNewsPosts(context, payload) {
      context.commit("setLoading", true);
      context.commit("clearError");
      axios
        .get(
            BASE_URL + 
            `/posts?page=${payload.page}&tag=${payload.tag}&search=${payload.search}`,
          {
            headers: {
              Accept: "application/json",
              APP_KEY: APP_KEY,
            },
          }
        )

        .then((response) => response.data)
        .then((newsPosts) => {
          context.commit("setLoading", false);
          context.commit("setNewsPosts", newsPosts);

        })
        .catch((error) => {
          context.commit("setLoading", false);
          context.commit("setError", error);
          console.log(error)
        });
    },

  },
};

export default PostModule;

