export default {
  methods: {
    isNullArray: function (arr) {
      if (!arr) return;
      return Object.keys(arr).length === 0 || undefined ? true : false;
    },
    getType: function (name) {
      if (!name) return;
      if (name == 'home' || name == 'articles') return 1
      if (name == 'posts') return 2
      if (name == 'videos') return 3
    },
  },
};