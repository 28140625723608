<template>
   <v-responsive class="mx-auto" :max-width="$vuetify.breakpoint.mobile?'100%':'70%'">
    <v-container>
      <v-row v-if="!loading">
        <v-col cols="12">
          <v-toolbar flat>
            <v-toolbar-title>المقالات</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-menu transition="slide-x-transition" bottom right max-height="400">
              <template v-slot:activator="{ on, attrs }">
                <v-btn depressed v-bind="attrs" v-on="on" color="primary" class="ma-2 white--text">
                  <v-icon left dark>
                    mdi-format-list-bulleted-square
                  </v-icon>
                  الاقسام
                </v-btn>
              </template>

              <v-list>
                <v-list-item @click="tag = ''">
                  <v-list-item-title>الكل</v-list-item-title>
                </v-list-item>
                <v-list-item v-for="(item, i) in tags.data" :key="i" @click="tag = item.id">
                  <v-list-item-title>{{ item.name }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-text-field v-model="search" append-icon="mdi-magnify" flat hide-details solo-inverted
              style="max-width: 300px" @click:append="getArticles()" v-on:keyup.enter="getArticles()" />
          </v-toolbar>
        </v-col>
      </v-row>
      <progress-circular />
      <v-row v-if="!loading">
        <article-card v-for="(article, i) in articles.data" :key="i" :size="3" :value="article" />
      </v-row>
      <v-row v-if="!loading" align="center">
        <v-col cols="3">
          <base-btn v-if="page !== 1" class="mr-0" square title="Previous page" @click="page--">
            <v-icon>mdi-chevron-right</v-icon>
          </base-btn>
        </v-col>

        <v-col v-if="articles.last_page > 1" class="text-center subheading" cols="6">
          صفحة {{ articles.current_page }} من {{ articles.last_page }}
        </v-col>

        <v-col class="text-left" cols="3">
          <base-btn v-if="articles.last_page > 1 && page < articles.last_page" class="ml-0" square title="Next page"
            @click="page++">
            <v-icon>mdi-chevron-left</v-icon>
          </base-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-responsive>

</template>

<script>
// Utilities
import {
  mapGetters,
} from 'vuex'

export default {
  name: 'Articles',

  components: {
    ArticleCard: () => import('@/components/ArticleCard'),
  },

  data: () => ({
    layout: [3, 3, 3, 2, 2, 3, 3, 3, 3, 3],
    page: 1,
    search: '',
    tag: '',
    exceptTag:'',
   

  }),
  created() { this.getArticles(); this.getTagsByType(); },

  computed: {
    ...mapGetters(['loading', 'articles', 'tags']),
  },

  methods: {
    getArticles() {
      this.$store.dispatch('getArticles', { 'page': this.page, 'search': this.search ,'tag': this.tag,'exceptTag': this.exceptTag });

    },
    getTagsByType() {
      this.$store.dispatch("getTagsByType", this.getType(this.$route.name)
      );
    },
  },
  watch: {
    page() {
      this.getArticles()
      window.scrollTo(0, 0)
    },
    tag() {
      this.page= 1;
      this.getArticles()
      window.scrollTo(0, 0)
    },
    search(val) {
      if (val == '') {
        this.tag='';
        this.getArticles()
        window.scrollTo(0, 0)
      }
    },
  },
}
</script>
