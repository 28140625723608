import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import 'font-awesome/css/font-awesome.min.css'
Vue.use(Vuetify)

export default new Vuetify({
  rtl:true,
  dark:true,
  theme: {
    dark: localStorage.getItem('theme') === 'dark',
    themes: {
      light: {
        primary: '#CBAA5C',
        secondary: '#083759',
      },
       dark: {
        primary: '#CBAA5C',
        secondary: '#083759',
            
            },
    },
  },
})
